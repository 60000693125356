.container {
  max-width: 230mm;
  margin-right: auto;
  margin-left: auto;
  font-size: 17px;
}
.header {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.header div {
  text-align: center;
}
.header img {
  margin-top: 5px;
  max-width: 120px;
}
.borderboldbottom {
  margin-bottom: 5px;
  padding-bottom: 5px;
  width: 100%;
  border-bottom: 2px solid rgb(75, 75, 75);
}
.officename {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.5em;
  color: red;
  border-bottom: solid black 1.5px;
}
.letterdate {
  text-align: right;
  margin-top: 15px;
}
.lettertitle {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.5em;
  padding: 5px;
  margin-top: 10px;
  font-weight: bolder !important;
}
.letterto {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}
.lettertohead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}
.body p {
  line-height: 0.5em;
  text-align: justify;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
.payment_items {
  text-transform: capitalize;
}
.payment_items:not(:last-child)::after {
  content: " and ";
}
.text-uppercase {
  text-transform: uppercase;
}
ul {
  text-align: justify;
}

ul#letter li {
  list-style-type: decimal;
  font-size: 17px;
}

ul ol {
  list-style-type: armenian;
}
.text-center {
  text-align: center;
}
