.ReactModal__Content {
  top: 59%;
  margin-top: 5%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: none !important;
  padding: 0 !important;
}
.tb-1 {
  font-weight: 100;
}
.tb-2 {
  font-weight: 200;
}
.tb-3 {
  font-weight: 300;
}

@media only screen and (max-width: 600px) {
  .ReactModal__Content {
    margin: 16% 4% 0 4%;
  }
}
.modal-dialog {
  margin: 0 auto !important;
}
.ReactModal__Overlay {
  background-color: rgba(170, 170, 170, 0.5) !important;
  z-index: 999;
}
.modal-body {
  max-height: 70vh;
  overflow-y: auto;
}
.id-template {
  max-width: 350px !important;
  max-height: 250px !important;
  border-radius: 10px;
}
.pointer {
  cursor: pointer !important;
}
div.applicant_menu {
  cursor: pointer !important;
  padding-left: 20px !important;
  text-transform: uppercase;
}
.applicant_menu_span {
  font-size: 7em !important;
  margin: 10px !important;
}
.card.card-light-red {
  background: #e97878 !important;
  color: #ffffff !important;
}
.card.card-light-red:hover {
  background: #e97878d3 !important;
}
.card.card-dark-green {
  background: #166327 !important;
  color: #ffffff !important;
}
.card.card-dark-green:hover {
  background: #166327cc !important;
}
.uploadedDocument_div {
  display: flex;
  align-content: center;
  justify-content: center;
}
.uploadedDocument {
  max-width: 100%;
  border-radius: 20px;
  margin-top: 5px;
}
.bordered_bottom {
  border: solid 1px lightgrey !important;
  padding: 10px 10px;
}
.br-10 {
  border-radius: 10px;
}
.fs-20 {
  font-size: large;
  text-align: justify;
}
